import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Modal,
  Typography,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";

// import searchIcon from "../../assets/search_black_24dp.svg";
// import notificationIcon from "../../assets/notifications_black_24dp.svg";
// import settingsIcon from "../../assets/settings_black_24dp.svg";
import profilePic from "../../assets/Avatar.png";

import { SearchIcon, notificationIcon, settingsIcon } from "assets/svg/DashboardSvg";

import "./NavRight.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { onGstUpdate } from "redux/actions";
import NavNotification from "./NavNotification";

const { Title, Text } = Typography;

const NavRight = () => {

  let gst = useSelector((state) => state?.gst?.gst);
  const dispatch = useDispatch();

  const [showGstModal, setShowGstModal] = useState(false);
  const [gstPercent, setGstPercent] = useState(gst);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(gst);

  if (!gst) {
    gst = "N.A.";
  }

  const sendRequest = async () => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `/admin/update-tax-percentage`,
        { tax_percentage: gstPercent }
      );

      const resGst = res.data.data.tax_percentage;

      dispatch(onGstUpdate(resGst));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const [profilePicUrl, setProfilePicUrl] = useState('');

  const profilePicHandler = async () => {
    const hrmsuserid = localStorage.getItem('hrmsuserid');
    // alert('hrmsUserId:', hrmsuserid);
    console.log('hrmsuserid:', hrmsuserid);

    try {
      const res = await axios.get(`/admin/getloginadminpicture/${hrmsuserid}`);
      if (!res.data.success) {
        throw new Error(res.data.data);
      }
      setProfilePicUrl(res.data.data[0].profile_pic);
      console.log('profilePicUrl:', profilePicUrl);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  useEffect(() => {
    profilePicHandler();

  }, []);

  const GstModal = () => {
    return (
      <>


        <Title level={3} strong>
          GST (%)
        </Title>
        <InputNumber className="w-75" value={gstPercent} onChange={(v) => setGstPercent(v)} />
      </>
    );
  };

  const avatarMenu = (
    <Menu>
      {/* <Menu.Item
        onClick={() => {
          window.location.href = process.env.REACT_APP_HRMS_URL;
        }}
      >
        <span className="d-flex align-items-center">
       
          HRMS
        </span>
      </Menu.Item> */}

      <Menu.Item
        onClick={() => {
          window.location.href = "https://hc-inventory.reddotapps.com.sg";
        }}
      >
        <span className="d-flex align-items-center">
          {/* <Icon className="mr-2" component={VisibilityIcon} /> */}
          Inventory
        </span>
      </Menu.Item>
    </Menu>
  );

  const settingMenu = (
    <Menu>
      <Menu.Item onClick={() => setShowGstModal(true)}>{`GST (${gst}%)`}</Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* <ul className="nav-right">
        <li>
          <span className="m-3">
            <img src={searchIcon} />
          </span>
          <span className="m-3">
            <img src={notificationIcon} />
          </span>
          <span className="m-3">
            <img src={settingsIcon} />
          </span>
          <Dropdown overlay={menu} trigger={["click"]}>
            <span style={{cursor: "pointer"}}className="m-3">
              <img src={profilePic} />
            </span>
          </Dropdown>
        </li>
      </ul> */}

      <Modal
        visible={showGstModal}
        onCancel={() => setShowGstModal(false)}
        okText="Save"
        onOk={async () => {
          await sendRequest();
          setShowGstModal(false);
        }}
      >
        <Spin spinning={isLoading}>
          <GstModal />

        </Spin>
      </Modal>

      <div className="d-flex align-items-center dashboard_icons">
        <Icon className="mr-3" component={SearchIcon} />

        {/* <Icon className="mr-3" component={notificationIcon} />
         */}
        <NavNotification />


        <Dropdown className="mr-3" overlay={settingMenu} trigger={["click"]}>
          <Icon component={settingsIcon} />
        </Dropdown>

        <Dropdown className="mr-3" overlay={avatarMenu} trigger={["click"]}>
          <img style={{ cursor: "pointer", width: "50px", height: "50px", objectFit: "cover", borderRadius: "89px" }}
            src={profilePicUrl ? profilePicUrl : profilePic} />
        </Dropdown>
      </div>
    </>
  );
};

export default NavRight;
